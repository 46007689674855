
import { Getter } from "vuex-class";
import { WorkStatus } from "@/store/modules/activity.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PageSellerPartProgressHouse extends Vue {
    @Getter("activity/viewing") activity!: Activity;

    @Prop({ default: true }) readonly showPercentage!: boolean;

    get baseProgress() {
        let progress = 0;
        switch (this.activity.work_status) {
            case WorkStatus.startUp || WorkStatus.newDossier:
                progress = 1;
                break;
            case WorkStatus.published:
                progress = 2;
                break;
            case WorkStatus.firstOffer:
                progress = 3;
                break;
            case WorkStatus.sold:
                progress = 5;
                break;
        }

        return progress;
    }
}
